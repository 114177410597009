import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

// Assets
import logo from "../images/logo.png"

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const [currentPath, setCurrentPath] = useState("");

  // 使用 effect 钩子来在组件加载时获取当前的 URL 路径
  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentPath(window.location.pathname); // 设置当前路径
    }
  }, []);

  // 检查当前路径是否匹配当前链接
  const isActive = (path) => currentPath === path;

  return (
    <nav className="bg-transparent">
      <div className="max-w-7xl mx-auto px-8 py-5">
        <div className="flex items-center justify-between h-16">
          <div className="w-full justify-between flex items-center">
            <a className="text-white flex-shrink-0 font-poppins" href="/">
              <img
                style={{
                  display: "inline-block",
                  width: 32,
                  height: 32,
                  marginRight: 10,
                }}
                className="w-full h-full"
                src={logo}
                alt="logo"
              ></img>
              OrangeThink · HK
            </a>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <Link
                  className={`px-3 py-2 rounded-md text-sm font-medium font-poppins ${
                    isActive("/") ? "text-white bg-gradient-to-r from-pink to-purple" : "text-white opacity-50 hover:opacity-100"
                  }`}
                  to="/"
                >
                  Home
                </Link>
                <Link
                  className={`px-3 py-2 rounded-md text-sm font-medium font-poppins ${
                    isActive("/about") ? "text-white bg-gradient-to-r from-pink to-purple" : "text-white opacity-50 hover:opacity-100"
                  }`}
                  to="/about"
                >
                  About
                </Link>
                <Link
                  className={`px-3 py-2 rounded-md text-sm font-medium font-poppins ${
                    isActive("/careers") ? "text-white bg-gradient-to-r from-pink to-purple" : "text-white opacity-50 hover:opacity-100"
                  }`}
                  to="/careers"
                >
                  Careers
                </Link>
                <Link
                  className={`px-3 py-2 rounded-md text-sm font-medium font-poppins ${
                    isActive("/contact") ? "text-white bg-gradient-to-r from-pink to-purple" : "text-white opacity-50 hover:opacity-100"
                  }`}
                  to="/contact"
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setOpenMenu(!openMenu)}
              className="text-gray-800 dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
            >
              <svg
                width="20"
                height="20"
                fill="white"
                className="h-8 w-8"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {openMenu && (
        <div className="md:hidden">
          <div className="md-px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a
              className={isActive("/") ? "bg-gradient-to-r from-pink to-purple text-gray-800 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium" : "text-gray-300 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"}
              href="/"
            >
              Home
            </a>
            <a
              className={isActive("/about") ? "bg-gradient-to-r from-pink to-purple text-gray-800 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium" : "text-gray-300 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"}
              href="/about"
            >
              About
            </a>
            <a
            className={isActive("/careers") ? "bg-gradient-to-r from-pink to-purple text-gray-800 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium" : "text-gray-300 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"}
              href="/careers"
            >
              Careers
            </a>
            <a
            className={isActive("/contact") ? "bg-gradient-to-r from-pink to-purple text-gray-800 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium" : "text-gray-300 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"}
              href="/contact"
            >
              Contact
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
